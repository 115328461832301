import { useSelector } from '@innedit/formidable';
import { UtilisateurModel } from '@innedit/innedit-react';
import { PageProps } from 'gatsby';
// import querystring from 'querystring';
import React, { FC, useEffect } from 'react';

import Content from '../components/Content';
import Layout from '../components/Layout';
import { ReducerUserProps } from '../reducers/user';

const SignInPage: FC<PageProps> = function ({
  location: { href, pathname, search },
}) {
  const user = useSelector(
    (state: { user?: ReducerUserProps }) => state.user?.item,
  );
  // const params = search ? querystring.parse(search.substring(1)) : {};
  // console.info('params', params);
  useEffect(() => {
    UtilisateurModel.signInWithEmailLink(href);
  }, [href]);

  return (
    <Layout pathname={pathname} showTitle title="pages.signin.title">
      <Content className="mx-6 md:mx-auto !max-w-screen-sm">
        {user && <div>Vous êtes authentifié</div>}
      </Content>
    </Layout>
  );
};

export default SignInPage;
